import React from 'react';

import MainLayout from '../components/layout/MainLayout';
import useLogout from './login/useLogout';
import { BaseButton } from '../components/styles/Buttons';
import { CenteredContainer } from '../components/styles/Containers';
import { StepTitle } from '../components/styles/Titles';

export const NOT_FOUND_MESSAGE = 'Looks like the recommendation or consent code is invalid';

const NotFound = () => {
  const logout = useLogout();

  const handleGoBackClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.href = bambinoLink;
  };

  return (
    <MainLayout>
      <CenteredContainer>
        <StepTitle>
          {NOT_FOUND_MESSAGE}
        </StepTitle>

        <BaseButton color="info" type="button" onClick={handleGoBackClick}>
          Go Back
        </BaseButton>
      </CenteredContainer>
    </MainLayout>
  );
};

export default NotFound;
