/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import API from '../../Api';
import { AxiosResponse } from 'axios';
import { ConsentSitter } from '../../types/SitterTypes';
import { ConsentStatus, RecommendationStatus, Steps } from '../../types/ConsentTypes';
import { errorToastOptions } from '../../constants/toasts';
import { getConsentFlowCurrentStep } from '../../helpers/ConsentHelper';
import { Parent } from '../../types/ParentTypes';
import { Recommendation } from '../../types/RecommendationTypes';

const useConsent = () => {
  const { token = '' } = useParams<{ token: string }>();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [step, setStep] = useState<Steps>(Steps.LOGIN);
  const [sitter, setSitter] = useState<ConsentSitter | null>(null);
  const [parent, setParent] = useState<Parent | null>(null);
  const [recommendation, setRecommendation] = useState<Recommendation | null>(null);

  const [consentStatus, setConsentStatus] = useState<ConsentStatus>({
    parent_consented: false,
    consent_submitted: false,
    consent_request_response: '',
  });
  const [recommendationStatus, setRecommendationStatus] = useState<RecommendationStatus>({
    parent_recommended: false,
    recommendation_submitted: false,
    recommendation_request_response: '',
  });

  const loginToken = localStorage.getItem('bambino-sitters-token') || '';

  const mailSubject = 'I think you\'ll love Bambino!';
  const mailBody = `You should check out Bambino!\n
    I just gave a thumbs up for ${(sitter?.name || 'a sitter')} to register as a Sitter on Bambino.\n
    It's a private community where friends and neighbors find trusted, 
    parent-recommended Sitters. It seems like it really is babysitting
    made a whole lot easier and I think we're all going to love it!`;

  const getLoggedInUser = () => {
    const user = JSON.parse(localStorage.getItem('bambino-sitters-user') || '') as Parent;
    setParent(user);

    API.get(`/api/parentalconsentinfo/${token}`, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        if (res.data.data?.babysitter) {
          const babysitter = res.data.data.babysitter as ConsentSitter;
          setSitter(prevSitter => ({
            ...prevSitter,
            ...babysitter,
            rating: 0,
            will_use_sitter: '',
          }));
          setIsDataLoaded(true);

          if (babysitter.parent_confirmed) {
            setConsentStatus(prevConsentStatus => ({
              ...prevConsentStatus,
              parent_consented: true,
            }));

            if (babysitter.parent_confirmed_accepted !== undefined) {
              setConsentStatus(prevConsentStatus => ({
                ...prevConsentStatus,
                consent_request_response: 'gave',
              }));
            } else {
              setConsentStatus(prevConsentStatus => ({
                ...prevConsentStatus,
                consent_request_response: 'denied',
              }));
            }
          }
        } else {
          const message = 'The consent confirmation code is invalid';
          toast(message, errorToastOptions);
        }
      })
      .catch((error: Error) => {
        const message = error.message || 'Error retrieving consent info!';
        toast(message, errorToastOptions);
      });
  };

  const addRecommendation = (newParent: Parent) => {
    const errorMessage = 'Error creating new recommendation!';
    const recommend_data = {
      babysitter_id: sitter?.id || '',
      babysitter_name: sitter?.name || '',
      name: newParent?.name || '',
      email: '',
    };

    API.post('/api/addrecommendation', recommend_data, { headers: { 'X-Token': loginToken } })
      .then((response: AxiosResponse) => {
        setConsentStatus(prevConsentStatus => ({
          ...prevConsentStatus,
          consent_submitted: true,
          parent_consented: true,
        }));

        if (response.data.data?.status) {
          if (response.data.data?.recommendation.accepted) {
            setRecommendationStatus(prevRecommendationStatus => ({
              ...prevRecommendationStatus,
              parent_recommended: true,
            }));
          }

          setRecommendation(response.data.data?.recommendation as Recommendation || null);
        } else {
          setRecommendationStatus(prevRecommendationStatus => ({
            ...prevRecommendationStatus,
            recommendation_submitted: true,
            parent_recommended: false,
          }));

          toast(errorMessage, errorToastOptions);
        }
      })
      .catch((err: Error) => {
        setConsentStatus(prevConsentStatus => ({
          ...prevConsentStatus,
          consent_submitted: true,
          parent_consented: true,
        }));

        setRecommendationStatus(prevRecommendationStatus => ({
          ...prevRecommendationStatus,
          recommendation_submitted: true,
          parent_recommended: false,
        }));

        const message = err.message || errorMessage;
        toast(message, errorToastOptions);
      });
  };

  const parentalConfirm = (newParent: Parent) => {
    const errorMessage = 'Error sending parent consent confirmation!';
    const body = {
      confirmation_code: token,
      consented: true,
      parent_first_name: newParent?.first_name || '',
      parent_last_name: newParent?.last_name || '',
    };

    API.post('/api/confirmparentconsent', body, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        if (res.data.data?.success) {
          if (!res.data.data.has_already_recommended) {
            addRecommendation(newParent);
          } else {
            setConsentStatus(prevConsentStatus => ({
              ...prevConsentStatus,
              consent_submitted: true,
              parent_consented: true,
            }));
            setRecommendationStatus(prevRecommendationStatus => ({
              ...prevRecommendationStatus,
              parent_recommended: true,
            }));
          }
        } else {
          toast(errorMessage, errorToastOptions);
        }
      })
      .catch((error: Error) => {
        const message = error.message || errorMessage;
        toast(message, errorToastOptions);
      });
  };

  const parentalDecline = () => {
    const body = {
      confirmation_code: token,
      consented: false,
    };

    API.post('/api/confirmparentconsent', body, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        console.log(res);

        if (res.data.data?.success) {
          setConsentStatus(prevConsentStatus => ({
            ...prevConsentStatus,
            consent_submitted: true,
            parent_consented: false,
          }));
        } else {
          const message = 'Error denying parent consent confirmation!';
          toast(message, errorToastOptions);
        }
      })
      .catch((error: Error) => {
        const message = error.message || 'Error denying parent consent confirmation!';
        toast(message, errorToastOptions);
      });
  };

  const parentalDeclineRetracted = () => {
    setConsentStatus(prevConsentStatus => ({
      ...prevConsentStatus,
      consent_submitted: false,
    }));
  };

  const recommendationConfirm = (will_use_sitter: boolean, newSitter: ConsentSitter) => {
    const body = {
      recommendation_token: recommendation?.token as string || '',
      accepted: will_use_sitter,
      rating: newSitter?.rating || 1,
      will_use_sitter,
      about_sitter: newSitter?.about_sitter || '',
      created_by_consent: true,
    };

    API.post('/api/performrecommendation', body, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        if (res.data.data?.status) {
          setRecommendationStatus(prevRecommendationStatus => ({
            ...prevRecommendationStatus,
            recommendation_submitted: true,
            parent_recommended: true,
          }));
        } else {
          const message = 'Error submitting parent recommendation';
          toast(message, errorToastOptions);
        }
      })
      .catch((error: Error) => {
        const message = error.message || 'Error submitting parent recommendation';
        toast(message, errorToastOptions);
      });
  };

  const recommendationDecline = () => {
    const body = {
      recommendation_token: recommendation?.token as string || '',
      accepted: false,
      created_by_consent: true,
    };

    API.post('/api/performrecommendation', body, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        if (res.data.data?.status) {
          setRecommendationStatus(prevRecommendationStatus => ({
            ...prevRecommendationStatus,
            recommendation_submitted: true,
            parent_recommended: false,
          }));
        } else {
          const message = 'Error declining parent recommendation';
          toast(message, errorToastOptions);
        }
      })
      .catch((error: Error) => {
        const message = error.message || 'Error declining parent recommendation';
        toast(message, errorToastOptions);
      });
  };

  const nextStep = (newStep: Steps) => {
    setStep(newStep);
  };

  useEffect(() => {
    if (token && !isDataLoaded && !parent && !sitter && loginToken) {
      getLoggedInUser();
    }
  }, [token, isDataLoaded, parent, sitter, loginToken]);

  useEffect(() => {
    if (isDataLoaded) {
      setStep(getConsentFlowCurrentStep(consentStatus, recommendationStatus));
    }
  }, [consentStatus, recommendationStatus, isDataLoaded]);

  return {
    isDataLoaded,
    mailSubject,
    mailBody,
    nextStep,
    parent,
    parentalConfirm,
    parentalDecline,
    parentalDeclineRetracted,
    recommendationConfirm,
    recommendationDecline,
    setParent,
    setSitter,
    sitter,
    step,
  };
};

export default useConsent;
