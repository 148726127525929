import React from 'react';

import { BaseButton } from '../../../components/styles/Buttons';
import { ConsentSitter } from '../../../types/SitterTypes';
import { DeclineLink } from '../../../components/styles/Texts';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  parentalDeclineRetracted: () => void;
  sitter: ConsentSitter | null;
}

const ConsentDeniedStep: React.FC<Props> = ({
  parentalDeclineRetracted,
  sitter,
}) => {
  const sitterName = sitter?.name || 'this person';
  const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';

  return (
    <>
      <StepTitle>
        Thank you for considering Bambino
      </StepTitle>

      <br />

      <p>
        Thank you for considering Bambino for {sitterName}.
        We understand that you still have reservations and are not willing to provide consent
        for {sitterName} to participate in the Bambino network. If you change your mind,
        please click the link on the original email to update your preferences. We welcome your
        return at anytime!
      </p>

      <p>
        As a reminder, the safety of our sitters and the children they look after is of paramount
        importance to us. If you ever have questions or concerns, you can reach us at 844-622-6246
        or visit our contact us page for more options.
      </p>

      <p>
        Thank you!
      </p>

      <div className="d-flex justify-content-center align-items-center">
        <DeclineLink onClick={parentalDeclineRetracted}>
          OOPS, I need to go back!
        </DeclineLink>
        <a href={bambinoLink} target="_blank" rel="noreferrer">
          <BaseButton color="info" type="button" style={{ width: 250 }}>
            Learn more about Bambino
          </BaseButton>
        </a>
      </div>
    </>
  );
};

export default ConsentDeniedStep;
