import React from 'react';

import PhoneInputStep from './PhoneInputStep';
import PhoneVerificationStep from './PhoneVerificationStep';
import useLogin, { LoginSteps, UseLoginActions } from './useLogin';

const Login: React.FC<UseLoginActions> = (props) => {
  const {
    phone,
    sendCodeLoading,
    sendVerificationCode,
    setPhone,
    step,
    verifyPhone,
    verifyPhoneLoading,
  } = useLogin(props);

  const displayCurrentStep = () => {
    switch (step) {
      case LoginSteps.PHONE_INPUT:
        return (
          <PhoneInputStep
            isLoading={sendCodeLoading}
            phone={phone}
            setPhone={setPhone}
            sendVerificationCode={sendVerificationCode}
          />
        );

      case LoginSteps.PHONE_VERIFICATION:
        return (
          <PhoneVerificationStep
            isLoading={verifyPhoneLoading}
            phone={phone}
            sendVerificationCode={sendVerificationCode}
            verifyPhone={verifyPhone}
          />
        );

      default:
        return <div>Error</div>;
    }
  };

  return (
    <>
      {displayCurrentStep()}
    </>
  );
};

export default Login;
