import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'reactstrap';

import { BaseButton } from '../../components/styles/Buttons';
import { CenteredInput } from '../../components/styles/Fields';
import { formatPhoneForSms } from '../../helpers/PhoneHelper';
import { InputDescription, InputLink } from '../../components/styles/Texts';
import { StepTitle } from '../../components/styles/Titles';
import { verificationCodeMask } from '../../constants/masks';

export interface Props {
  isLoading: boolean;
  phone: string;
  sendVerificationCode: (phoneNumber: string) => void;
  verifyPhone: (phoneNumber: string, verificationCode: string) => void;
}

const PhoneVerificationStep: React.FC<Props> = ({
  isLoading,
  phone,
  sendVerificationCode,
  verifyPhone,
}) => {
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(true);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    sendVerificationCode(phone);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    verifyPhone(phone, code);
  };

  const formattedPhone = formatPhoneForSms(phone);

  return (
    <Container>
      <StepTitle>
        Enter your verification code
      </StepTitle>

      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <Form
            data-testid="code-input-form"
            onSubmit={handleSubmit}
          >
            <CenteredInput
              className="form-control"
              type="text"
              placeholder="_ _ _ _ _ _"
              required
              value={code}
              mask={verificationCodeMask}
              onAccept={(_, { unmaskedValue }) => {
                setCode(unmaskedValue);

                if (unmaskedValue.length === 6) {
                  setDisabled(false);
                } else {
                  setDisabled(true);
                }
              }}
            />

            <div className='d-flex justify-content-between'>
              <InputDescription>
                We just texted a verification code to {formattedPhone}.
              </InputDescription>
              <InputLink onClick={handleClick}>
                Resend
              </InputLink>
            </div>

            <br />

            <BaseButton
              color="info"
              type="submit"
              disabled={disabled || isLoading}
            >
              {isLoading ? (
                <div>
                  <div className="spinner-border spinner-border-sm ml-1" role="status" />
                </div>
              ) : 'Verify'}
            </BaseButton>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default PhoneVerificationStep;
