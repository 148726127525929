import React from 'react';

import useLogout from '../../login/useLogout';
import { BaseButton } from '../../../components/styles/Buttons';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  message: string;
}

const ConsentMessage: React.FC<Props> = ({ message }) => {
  const logout = useLogout();

  const handleGoBackClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.href = bambinoLink;
  };

  return (
    <>
      <StepTitle>{message}</StepTitle>

      <BaseButton color="info" type="button" onClick={handleGoBackClick}>
        Go Back
      </BaseButton>
    </>
  );
};

export default ConsentMessage;
