import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

import { bambinoOrange } from '../../assets/colors';

const Spinner: React.FC = () => (
  <SpinnerContainer data-testid="spinner">
    <ClipLoader
      size={60}
      color={bambinoOrange}
      loading
    />
  </SpinnerContainer>
);

export default Spinner;

const SpinnerContainer = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
`;

