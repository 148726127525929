import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import API from '../../Api';
import { errorToastOptions } from '../../constants/toasts';
import { ReducedSitter } from '../../types/SitterTypes';

export interface InitialInfo {
  isDataLoaded: boolean;
  sitter: ReducedSitter | null;
  errorMessage: string;
}

export interface Recommendation {
  parent_recommended: boolean;
  recommendation_submitted: boolean;
  recommendation_request_response: string;
}

export interface RecommendationPayload {
  recommendation_token: string;
  accepted: boolean;
  will_use_sitter: boolean;
  rating: number;
  how_do_you_know: string;
  about_sitter: string;
  parent_first_name: string;
  parent_last_name: string;
}

export enum Steps {
  WELCOME = 'WELCOME',
  LOGIN = 'LOGIN',
  COMPLETE_INFORMATION = 'COMPLETE_INFORMATION',
  RATING_RECOMMENDATION = 'RATING_RECOMMENDATION',
  RECOMMENDATION_MESSAGE = 'RECOMMENDATION_MESSAGE',
}

const useRecommendation = () => {
  const { token = '' } = useParams<{ token: string }>();
  const [recommendationLoading, setRecommendationLoading] = useState(false);
  const [step, setStep] = useState<Steps>(Steps.WELCOME);
  const [initialInfo, setInitialInfo] = useState<InitialInfo>({
    isDataLoaded: false,
    sitter: null,
    errorMessage: '',
  });
  const [recommendation, setRecommendation] = useState<Recommendation>({
    parent_recommended: false,
    recommendation_submitted: false,
    recommendation_request_response: '',
  });
  const [payload, setPayload] = useState<RecommendationPayload>({
    recommendation_token: token,
    accepted: true,
    will_use_sitter: true,
    rating: 0,
    how_do_you_know: '',
    about_sitter: '',
    parent_first_name: '',
    parent_last_name: '',
  });

  const getRecommendationInfo = () => {
    API.get(`/api/getrecommendationbycode/${token}`)
      .then((res: AxiosResponse) => {
        setInitialInfo(prevInfo => (
          {
            ...prevInfo,
            sitter: res.data.data.babysitter as ReducedSitter,
            isDataLoaded: true,
          })
        );
      })
      .catch((error: Error) => {
        const errorMessage = error.message || 'Server error';
        setInitialInfo(prevInfo => (
          {
            ...prevInfo,
            errorMessage,
            isDataLoaded: false,
          })
        );

        nextStep(Steps.RECOMMENDATION_MESSAGE);
        toast(errorMessage, errorToastOptions);
      });
  };

  const checkCanRecommend = (xToken: string) => {
    const { sitter } = initialInfo;

    if (sitter) {
      API.get(`/api/canrecommend/${sitter._id}?code=${token}`, { headers: { 'X-Token': xToken } })
        .then((res: AxiosResponse) => {
          if (!res.data.data?.canrecommend) {
            nextStep(Steps.RECOMMENDATION_MESSAGE);
            setRecommendation(prevRecommendation => ({
              ...prevRecommendation,
              parent_recommended: true,
              recommendation_submitted: false,
            }));
          } else {
            nextStep(Steps.COMPLETE_INFORMATION);
            setRecommendation(prevRecommendation => ({
              ...prevRecommendation,
              parent_recommended: false,
              recommendation_submitted: false,
            }));
          }
        })
        .catch((error: Error) => {
          const errorMessage = error.message || 'Error retrieving recommendation info';
          nextStep(Steps.RECOMMENDATION_MESSAGE);
          toast(errorMessage, errorToastOptions);
        });
    }
  };

  const recommendationConfirm = (loginToken: string) => {
    setRecommendationLoading(true);

    API.post('/api/performrecommendation', payload, { headers: { 'X-Token': loginToken } })
      .then((res: AxiosResponse) => {
        if (res.data.data && res.data.data.status && res.data.data.status === true) {
          setRecommendation(prevRecommendation => ({
            ...prevRecommendation,
            recommendation_submitted: true,
            parent_recommended: true,
          }));
        } else {
          const errorMessage = 'Error submitting parent recommendation';
          toast(errorMessage, errorToastOptions);
        }

        nextStep(Steps.RECOMMENDATION_MESSAGE);
        setRecommendationLoading(false);
      })
      .catch((error: Error) => {
        const errorMessage =
          error.message ||
          'We are sorry. An unknown error ocurred when submitting the recommendation';
        nextStep(Steps.RECOMMENDATION_MESSAGE);
        setRecommendationLoading(false);
        toast(errorMessage, errorToastOptions);
      });
  };

  const nextStep = (newStep: Steps) => {
    setStep(newStep);
  };

  useEffect(() => {
    if (token && !initialInfo.isDataLoaded && !initialInfo.sitter && !initialInfo.errorMessage) {
      getRecommendationInfo();
    }
  }, [initialInfo, token]);

  return {
    checkCanRecommend,
    initialInfo,
    nextStep,
    payload,
    recommendation,
    recommendationConfirm,
    recommendationLoading,
    setPayload,
    step,
  };
};

export default useRecommendation;
