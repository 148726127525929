/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { BaseButton } from '../../../components/styles/Buttons';
import { Parent } from '../../../types/ParentTypes';
import { QuestionLabel } from '../../../components/styles/Texts';
import { RadioOptionsGroup } from '../../../components/styles/Fields';
import { RecommendationPayload, Steps } from '../useRecommendation';
import { ReducedSitter } from '../../../types/SitterTypes';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  nextStep: (step: Steps) => void;
  sitter: ReducedSitter | null;
  setPayload: React.Dispatch<React.SetStateAction<RecommendationPayload>>;
}

export enum HowYouKnowOptions {
  BABYSAT_FOR_ME = 'BABYSAT_FOR_ME',
  RELATIVE = 'RELATIVE',
  FRIEND = 'FRIEND',
  NEIGHBOR = 'NEIGHBOR',
  OTHER = 'OTHER',
  NOT_SET = 'NOT_SET',
}

const howYouKnowMapping: { [HowYouKnowOptions: string]: string } = {
  [HowYouKnowOptions.BABYSAT_FOR_ME]: 'Babysat for me previously',
  [HowYouKnowOptions.RELATIVE]: 'Relative',
  [HowYouKnowOptions.FRIEND]: 'Friend',
  [HowYouKnowOptions.NEIGHBOR]: 'Neighbor',
  [HowYouKnowOptions.OTHER]: 'Other',
};

const howYouKnowRadioButtons = [
  {
    value: HowYouKnowOptions.BABYSAT_FOR_ME,
    label: howYouKnowMapping[HowYouKnowOptions.BABYSAT_FOR_ME],
  },
  {
    value: HowYouKnowOptions.RELATIVE,
    label: howYouKnowMapping[HowYouKnowOptions.RELATIVE],
  },
  {
    value: HowYouKnowOptions.FRIEND,
    label: howYouKnowMapping[HowYouKnowOptions.FRIEND],
  },
  {
    value: HowYouKnowOptions.NEIGHBOR,
    label: howYouKnowMapping[HowYouKnowOptions.NEIGHBOR],
  },
  {
    value: HowYouKnowOptions.OTHER,
    label: 'Other (teacher, coach, etc.)',
  },
];

const CompleteInformationStep: React.FC<Props> = ({
  nextStep,
  sitter,
  setPayload,
}) => {
  const parent = JSON.parse(localStorage.getItem('bambino-sitters-user') || '{}') as Parent;

  const [firstName, setFirstName] = useState(parent.first_name || '');
  const [lastName, setLastName] = useState(parent.last_name || '');
  const [howYouKnow, setHowYouKnow] = useState(HowYouKnowOptions.NOT_SET);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (firstName && lastName && howYouKnow !== HowYouKnowOptions.NOT_SET) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [firstName, lastName, howYouKnow]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    setPayload(prevPayload => ({
      ...prevPayload,
      parent_first_name: firstName,
      parent_last_name: lastName,
      how_do_you_know: howYouKnowMapping[howYouKnow],
    }));
    nextStep(Steps.RATING_RECOMMENDATION);
  };

  return (
    <Container>
      <StepTitle>
        Please complete the following
      </StepTitle>

      <Form
        data-testid="complete-information-form"
        onSubmit={handleSubmit}
      >
        <Row>
          <p>
            Your full name
          </p>
        </Row>

        <Row>
          <Col sm={{ size: 3, offset: 3 }}>
            <Input
              type="text"
              placeholder="Your first name"
              className="form-control"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.currentTarget.value);
              }}
            />
          </Col>

          <Col sm={{ size: 3 }}>
            <Input
              type="text"
              placeholder="Your last name"
              className="form-control"
              value={lastName}
              onChange={(event) => {
                setLastName(event.currentTarget.value);
              }}
            />
          </Col>
        </Row>

        <Row>
          <QuestionLabel>
            How do you know {sitter?.name || 'this sitter'}?
          </QuestionLabel>
        </Row>

        <Row>
          <Col sm={{ size: 3, offset: 5 }}>
            <RadioOptionsGroup>
              {howYouKnowRadioButtons.map(radio => (
                <FormGroup check key={radio.value}>
                  <Label check>
                    <Input
                      type="radio"
                      value={radio.value}
                      checked={radio.value === howYouKnow}
                      onChange={(event) => {
                        const value = event.currentTarget.value as HowYouKnowOptions;
                        setHowYouKnow(value);
                      }}
                    />{' '}
                    {radio.label}
                  </Label>
                </FormGroup>
              ))}
            </RadioOptionsGroup>
          </Col>
        </Row>

        <br />

        <BaseButton
          color="info"
          type="submit"
          disabled={disabled}
        >
          Next
        </BaseButton>
      </Form>

    </Container>
  );
};

export default CompleteInformationStep;
