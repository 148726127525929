import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import API from '../../Api';
import { errorToastOptions } from '../../constants/toasts';
import { formatPhoneForSms } from '../../helpers/PhoneHelper';
import { Parent } from '../../types/ParentTypes';

export interface UseLoginActions {
  actionAfterVerify: (loginToken: string) => void;
  actionAfterError: () => void;
}

export enum LoginSteps {
  PHONE_INPUT = 'PHONE_INPUT',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
}

const useLogin = ({
  actionAfterVerify,
  actionAfterError,
}: UseLoginActions) => {
  const [step, setStep] = useState<LoginSteps>(LoginSteps.PHONE_INPUT);
  const [phone, setPhone] = useState('');
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [verifyPhoneLoading, setVerifyPhoneLoading] = useState(false);

  const sendVerificationCode = (phoneNumber: string) => {
    const phoneNumberFormatted = formatPhoneForSms(phoneNumber);
    setSendCodeLoading(true);

    API.post('/api/sendverificationcode', { number_to: phoneNumberFormatted })
      .then((res: AxiosResponse) => {
        if (res.data?.status === true) {
          setCodeSent(true);
        } else {
          setCodeSent(false);
          const message = 'Error sending verification code';
          toast(message, errorToastOptions);
        }

        setSendCodeLoading(false);
      })
      .catch((error: Error) => {
        setCodeSent(false);
        setSendCodeLoading(false);
        const errorMessage = error.message || 'Error sending verification code';
        toast(errorMessage, errorToastOptions);
      });
  };

  const verifyPhone = (phoneNumber: string, verificationCode: string) => {
    const body = {
      number_to: phoneNumber,
      registration_token: verificationCode,
      parent: true,
    };

    setVerifyPhoneLoading(true);

    API.post('/api/verifyuser', body)
      .then((res: AxiosResponse) => {
        const loginToken = res.data.data?.loginToken as string || '';
        const user = res.data.data?.user as Parent || {};

        localStorage.setItem('bambino-sitters-token', loginToken);
        localStorage.setItem('bambino-sitters-user', JSON.stringify(user));

        actionAfterVerify(loginToken);
        setVerifyPhoneLoading(false);
      })
      .catch((error: Error) => {
        const errorMessage = error.message || 'Error verifying user';
        actionAfterError();

        setVerifyPhoneLoading(false);
        toast(errorMessage, errorToastOptions);
      });
  };

  useEffect(() => {
    if (step === LoginSteps.PHONE_INPUT && codeSent) {
      setStep(LoginSteps.PHONE_VERIFICATION);
    }
  }, [codeSent]);

  return {
    codeSent,
    phone,
    sendCodeLoading,
    sendVerificationCode,
    setPhone,
    step,
    verifyPhone,
    verifyPhoneLoading,
  };
};

export default useLogin;
