import React, { useEffect, useState } from 'react';
import ReactStars from 'react-stars';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import { bambinoLightOrange, bambinoOrange } from '../../../assets/colors';
import { BaseButton } from '../../../components/styles/Buttons';
import { ConsentSitter } from '../../../types/SitterTypes';
import { RadioOptionsGroup, ThumbsRadio } from '../../../components/styles/Fields';
import { StepTitle } from '../../../components/styles/Titles';

import thumbsDownOff from '../../../assets/img/thumb-down-off.png';
import thumbsDownOn from '../../../assets/img/thumb-down-on.png';
import thumbsUpOff from '../../../assets/img/thumb-up-off.png';
import thumbsUpOn from '../../../assets/img/thumb-up-on.png';

export interface Props {
  recommendationConfirm: (will_use_sitter: boolean, sitter: ConsentSitter) => void;
  setSitter: React.Dispatch<React.SetStateAction<ConsentSitter | null>>;
  sitter: ConsentSitter | null;
}

const wouldRecommendRadioButtons = [
  {
    value: 'NO',
    offImage: thumbsDownOff,
    onImage: thumbsDownOn,
  },
  {
    value: 'YES',
    offImage: thumbsUpOff,
    onImage: thumbsUpOn,
  },
];

const CompleteRecommendationStep: React.FC<Props> = ({
  recommendationConfirm,
  setSitter,
  sitter,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [rating, setRating] = useState(0);
  const [wouldRecommend, setWouldRecommend] = useState('');
  const [about, setAbout] = useState('');

  useEffect(() => {
    if (rating > 0 && about && about.length >= 25 && !!wouldRecommend) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [rating, about, wouldRecommend]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setSitter((prevSitter) => {
      if (prevSitter) {
        const newSitter = {
          ...prevSitter,
          rating,
          about_sitter: about,
        };

        const will_use_sitter = wouldRecommend === 'YES' ? true : false;

        recommendationConfirm(will_use_sitter, newSitter);

        return newSitter;
      }

      return null;
    });
  };

  return (
    <Container>
      <StepTitle>
        Rate Sitter
      </StepTitle>

      <p>
        Now that you&apos;ve given {sitter?.name || 'this sitter'} your consent, help complete their
        profile by providing a recommendation. {sitter?.name || 'This sitter'} will be automatically
        connected to all of your friends on Bambino and can start booking jobs!
      </p>

      <br />

      <Form data-testid='rating-form' onSubmit={handleSubmit}>
        <Row>
          <p>
            Please rate {sitter?.name || 'this sitter'}
          </p>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 3 }} className="d-flex justify-content-center">
            <ReactStars
              edit
              count={5}
              value={rating}
              size={40}
              color1={bambinoLightOrange}
              color2={bambinoOrange}
              onChange={newRating => setRating(newRating)}
              half={false}
            />
          </Col>
        </Row>

        <Row>
          <p>
            Would you recommend {sitter?.name || 'this sitter'} to your friends?
          </p>
        </Row>

        <Row>
          <div className="d-flex justify-content-center">
            <RadioOptionsGroup>
              <ThumbsRadio>
                {wouldRecommendRadioButtons.map((radio) => {
                  const checked = radio.value === wouldRecommend;

                  return (
                    <FormGroup check key={radio.value}>
                      <Label check>
                        <Input
                          type="radio"
                          value={radio.value}
                          checked={checked}
                          onChange={(event) => {
                            const value = event.currentTarget.value;
                            setWouldRecommend(value);
                          }}
                        />{' '}
                        <img src={checked ? radio.onImage : radio.offImage} />
                      </Label>
                    </FormGroup>
                  );
                })}
              </ThumbsRadio>
            </RadioOptionsGroup>
          </div>
        </Row>

        <Row>
          <p>
            Please provide a brief recommendation for {sitter?.name || 'this sitter'}
          </p>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <textarea
              className="form-control"
              rows={5}
              value={about}
              onChange={event => setAbout(event.currentTarget.value)}
              placeholder="Please provide at least 25 characters"
            />
          </Col>
        </Row>

        <br />

        <BaseButton
          color="info"
          type="submit"
          disabled={disabled}
        >
          Submit
        </BaseButton>
      </Form>

    </Container>
  );
};

export default CompleteRecommendationStep;
