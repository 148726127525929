import styled from 'styled-components';

import { bambinoOrange } from '../../assets/colors';

export const StepTitle = styled.h2`
  font-family: Effra-Light;
  color: ${bambinoOrange};
  text-transform: uppercase;
  margin-bottom: 2rem;
`;

export const SubTitle = styled.h3`
  text-transform: uppercase;
  color: ${bambinoOrange};
  font-size: 16px;
  font-weight: 500;
  padding-top: 25px;
  font-family: Effra-Light;
`;
