const isNumber = (phone: string) => !isNaN(Number(phone as unknown));

export const formatPhoneForSms = (phone: string) => {
  if (!isNumber(phone)) return null;

  if (phone) {
    if (phone.length === 10) {
      return `+1${phone}`;
    }

    return phone.length === 11 ? `+${phone}` : null;
  }

  return null;
};

