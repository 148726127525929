import { ConsentStatus, RecommendationStatus, Steps } from '../types/ConsentTypes';

type GetConsentFlowCurrentStep = (
  consentStatus: ConsentStatus,
  recommendationStatus: RecommendationStatus,
) => Steps;

export const getConsentFlowCurrentStep: GetConsentFlowCurrentStep = (
  consentStatus,
  recommendationStatus,
) => {
  if (!consentStatus.consent_submitted) {
    return !consentStatus.parent_consented ?
      Steps.COMPLETE_INFORMATION :
      Steps.CONSENT_ALREADY_GIVEN;
  }

  if (consentStatus.parent_consented) {
    if (!recommendationStatus.recommendation_submitted && !recommendationStatus.parent_recommended) {
      return Steps.CONSENT_GIVEN_COMPLETE_RECOMMENDATION;
    }

    if (recommendationStatus.recommendation_submitted && !recommendationStatus.parent_recommended) {
      return Steps.CONSENT_GIVEN_NOT_RECOMMENDED;
    }

    if (recommendationStatus.parent_recommended) {
      return Steps.CONSENT_GIVEN_RECOMMENDED;
    }
  }

  return Steps.CONSENT_DENIED;
};
