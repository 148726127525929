import React from 'react';

import useLogout from '../../login/useLogout';
import { BaseButton } from '../../../components/styles/Buttons';
import { StepTitle } from '../../../components/styles/Titles';

const ConsentGivenStep: React.FC = () => {
  const logout = useLogout();

  const handleGoBackClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.href = bambinoLink;
  };

  return (
    <>
      <StepTitle>
        Consent request already submitted
      </StepTitle>

      <p>
        You or another person has already given this babysitter consent to become a sitter at Bambino.
      </p>

      <BaseButton color="info" type="button" onClick={handleGoBackClick}>
        Go Back
      </BaseButton>
    </>
  );
};

export default ConsentGivenStep;
