/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';

import { errorToastOptions } from './constants/toasts';
import { getHeaders } from './helpers/NetworkHelper';

const defaultUrl: string = 'https://stg.admin.bambinoapp.com';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || defaultUrl,
});

axiosInstance.interceptors.request.use((config) => {
  const headers: any = getHeaders();

  config.headers = {
    ...config.headers,
    ...headers,
  };

  return config;
}, (error: Error) => {
  toast(error.message || 'Server error', errorToastOptions);
  return Promise.reject(error);
});

export default axiosInstance;
