/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

import { bambinoGrey, bambinoLightGrey, white } from '../../assets/colors';

type StyledFooterLinkProps = {
  hasBorderRight?: boolean;
  href?: string;
  target?: string;
  className?: string;
  addPaddingLeft?: boolean;
};

type StyledFooterIconProps = {
  src: string | null | undefined;
};

const StyledFooter = styled.footer`
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${bambinoGrey};
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
`;

const StyledFooterSocialIconsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const StyledFooterLink = styled.a<StyledFooterLinkProps>`
  color: ${white};
  text-decoration: none;
  padding-right: 1rem;
  padding-left: ${({ addPaddingLeft }) => addPaddingLeft ? '1rem' : '0'};
  border-right: ${({ hasBorderRight }) => hasBorderRight ? '1px solid white' : 'none'};
  :hover {
    color: ${bambinoLightGrey};
  }
`;

const StyledCopyrightLabel = styled.span`
  color: ${bambinoLightGrey};
  font-size: 0.8rem;
`;

const StyledFooterInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  color: ${white};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledFooterTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  color: ${white};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledFooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  color: ${white};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
  }
`;

const StyledFooterIcon: React.FC<StyledFooterIconProps> = styled.img`
  width: 30px;
  height: 30px;
  margin-left: 1rem;
  color: ${white};

  @media (max-width: 768px) {
    margin-left: 1rem;
  }
`;

export {
  StyledCopyrightLabel,
  StyledFooter,
  StyledFooterBottom,
  StyledFooterIcon,
  StyledFooterInfoSection,
  StyledFooterLink,
  StyledFooterSocialIconsContainer,
  StyledFooterTop,
};
