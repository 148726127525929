import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Consent from './pages/consent/Consent';
import NotFound from './pages/NotFound';
import Recommendation from './pages/recommendation/Recommendation';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/not-found">
        <NotFound />
      </Route>

      <Route path='/consent/:token'>
        <Consent />
      </Route>

      <Route path='/recommendation/:token'>
        <Recommendation />
      </Route>

      <Redirect to="/not-found" />
    </Switch>
  </BrowserRouter>
);

export default Routes;
