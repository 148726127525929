import React from 'react';

import CompleteInformationStep from './steps/CompleteInformationStep';
import Login from '../login/Login';
import MainLayout from '../../components/layout/MainLayout';
import RatingRecommendationStep from './steps/RatingRecommendationStep';
import RecommendationMessageStep from './steps/RecommendationMessageStep';
import WelcomeStep from './steps/WelcomeStep';
import useRecommendation, { Steps } from './useRecommendation';
import { CenteredContainer } from '../../components/styles/Containers';

const Recommendation = () => {
  const {
    checkCanRecommend,
    initialInfo,
    nextStep,
    recommendation,
    recommendationConfirm,
    recommendationLoading,
    setPayload,
    step,
  } = useRecommendation();

  const displayCurrentStep = () => {
    switch (step) {
      case Steps.WELCOME:
        return (
          <WelcomeStep
            {...initialInfo}
            nextStep={nextStep}
          />
        );

      case Steps.LOGIN:
        return (
          <Login
            actionAfterError={() => nextStep(Steps.RECOMMENDATION_MESSAGE)}
            actionAfterVerify={checkCanRecommend}
          />
        );

      case Steps.COMPLETE_INFORMATION:
        return (
          <CompleteInformationStep
            nextStep={nextStep}
            setPayload={setPayload}
            sitter={initialInfo.sitter}
          />
        );

      case Steps.RATING_RECOMMENDATION:
        return (
          <RatingRecommendationStep
            isLoading={recommendationLoading}
            recommendationConfirm={recommendationConfirm}
            setPayload={setPayload}
            sitter={initialInfo.sitter}
          />
        );

      case Steps.RECOMMENDATION_MESSAGE:
      default:
        return (
          <RecommendationMessageStep
            isDataLoaded={initialInfo.isDataLoaded}
            parent_recommended={recommendation.parent_recommended}
            recommendation_submitted={recommendation.recommendation_submitted}
            sitter={initialInfo.sitter}
          />
        );
    }
  };

  return (
    <MainLayout>
      <CenteredContainer>
        {displayCurrentStep()}
      </CenteredContainer>
    </MainLayout>
  );
};

export default Recommendation;
