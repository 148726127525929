import styled from 'styled-components';
import { Container } from 'reactstrap';

import { inputGrey, lighterGrey, white } from '../../assets/colors';

export const CenteredContainer = styled.div`
  text-align: center;
`;

export const ConsentContainer = styled.div`
  text-align: center;
  padding: 0 10rem;
`;

export const ScrollableContainer = styled(Container)`
  overflow-y: scroll;
  height: 70vh;
  text-align: center;
`;

export const InternalScrollableContainer = styled.div`
  margin-bottom: 2em;
  margin-top: 1em;
  color: ${inputGrey};
  font-family: Effra-Light;
  font-weight: 500;
  font-size: 14px;
  height: 200px;
  overflow: auto;
  background-color: ${white};
  border: 2px solid ${lighterGrey};
  border-radius: 5px;
  text-align: left;
  padding: 20px;

  ul {
    padding-left: 1rem;

    li {
      margin-bottom: 0.5rem;
    }
  }
`;
