export interface HeaderType {
  'X-App-Version': string;
  'X-API-Version': string;
  'X-Timestamp': number;
  'X-Token'?: string;
}

type GetHeaders = () => HeaderType;

export const getHeaders: GetHeaders = () => ({
  'X-App-Version': '604.0',
  'X-API-Version': '1.0',
  'X-Timestamp': new Date().getTime(),
});
