// Theme colors
export const bambinoGrey: string = '#504D4F';
export const bambinoLightGrey: string = '#868686';
export const bambinoLightOrange: string = '#FFCCC2';
export const bambinoLightTurqoise: string = '#2dd3e3';
export const bambinoOrange: string = '#F04E2E';
export const bambinoTurqoise: string = '#0dc5d7';

// General colors
export const white: string = '#FFFFFF';
export const whiteBackgorund: string = '#F7F7F7';
export const lightGrey: string = '#D9D9D9';
export const lighterGrey: string = '#DDDDDD';
export const inputGrey: string = '#757575';
