import styled from 'styled-components';

import { bambinoTurqoise, bambinoLightTurqoise } from '../../assets/colors';

export const InputDescription = styled.div`
  font-size: 12px;
`;

export const InputLink = styled.div`
  font-size: 12px;
  color: ${bambinoTurqoise};

  &:hover {
    color: ${bambinoLightTurqoise};
    text-decoration: underline;
    cursor: pointer:
  }
`;

export const QuestionLabel = styled.p`
  margin-top: 3rem;
`;

export const DeclineLink = styled.div`
  min-width: 180px;
  margin-right: 1rem;
  border: 1px solid ${bambinoTurqoise};
  border-radius: 4px;
  padding: 5px;
  text-transform: uppercase;
  color: ${bambinoTurqoise};
  font-weight: 600;

  &:hover {
    color: ${bambinoLightTurqoise};
    cursor: pointer;
  }
`;
