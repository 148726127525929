import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Routes from './Routes';

const App = () => (
  <Suspense fallback={<div className="loading" />} data-testid="appComponent">
    <Routes />
    <ToastContainer />
  </Suspense>
);

export default App;
