import React from 'react';
import { Container } from 'reactstrap';

import { BaseButton } from '../../../components/styles/Buttons';
import { InitialInfo, Steps } from '../useRecommendation';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props extends InitialInfo {
  nextStep: (step: Steps) => void;
}

const WelcomeStep: React.FC<Props> = ({
  isDataLoaded,
  errorMessage,
  sitter,
  nextStep,
}) => (
  <Container>
    <StepTitle>
      Welcome to Bambino
    </StepTitle>
    <p>
      Welcome to Bambino, a neighborhood babysitting app that connects parents
      with sitters that are recommended by their friends and neighbors.
    </p>

    {isDataLoaded && !errorMessage && (
      <>
        <p>
          {sitter?.name || ''} wants to join the community and needs a quick recommendation from you.
        </p>
        <BaseButton
          color="info"
          onClick={() => nextStep(Steps.LOGIN)}
        >
          Continue
        </BaseButton>
      </>
    )}
  </Container>
);

export default WelcomeStep;
