import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'reactstrap';

import { BaseButton } from '../../components/styles/Buttons';
import { CenteredInput } from '../../components/styles/Fields';
import { phoneNumberMask } from '../../constants/masks';
import { StepTitle } from '../../components/styles/Titles';

export interface Props {
  isLoading: boolean;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  sendVerificationCode: (phoneNumber: string) => void;
}

const PhoneInputStep: React.FC<Props> = ({
  isLoading,
  phone,
  sendVerificationCode,
  setPhone,
}) => {
  const [disabled, setDisabled] = useState(true);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    sendVerificationCode(phone);
  };

  return (
    <Container>
      <StepTitle>
        To get started, enter your mobile phone number
      </StepTitle>

      <Row>
        <Col sm={{ size: 6, offset: 3 }}>
          <Form
            data-testid="phone-input-form"
            onSubmit={handleSubmit}
          >
            <CenteredInput
              className="form-control"
              type="text"
              placeholder="(310) 555-0123"
              required
              value={phone}
              mask={phoneNumberMask}
              onAccept={(_, { unmaskedValue }) => {
                setPhone(unmaskedValue);

                if (unmaskedValue.length === 10) {
                  setDisabled(false);
                } else {
                  setDisabled(true);
                }
              }}
            />

            <p>
              We will send you a verification code to ensure you are a real person.
            </p>

            <BaseButton
              color="info"
              type="submit"
              disabled={disabled || isLoading}
            >
              {isLoading ? (
                <div>
                  <div className="spinner-border spinner-border-sm ml-1" role="status" />
                </div>
              ) : 'Send code'}
            </BaseButton>
          </Form>
        </Col>
      </Row>

    </Container>
  );
};

export default PhoneInputStep;
