const useLogout = () => {
  const logout = () => {
    localStorage.removeItem('bambino-sitters-token');
    localStorage.removeItem('bambino-sitters-user');
  };

  return logout;
};

export default useLogout;
