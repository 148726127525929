import React from 'react';
import styled from 'styled-components';
import { Navbar, NavbarBrand } from 'reactstrap';

import Footer from './Footer';
import logoFull from '../../assets/logos/bambino-icon-full.png';
import useLogout from '../../pages/login/useLogout';
import { white, whiteBackgorund } from '../../assets/colors';

interface Props {
  children: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const logout = useLogout();

  const handleGoBackClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.href = bambinoLink;
  };

  return (
    <Container>
      <Navbar color={white} light expand="md">
        <ClickableNavbarBrand onClick={handleGoBackClick}>
          <BambinoLogo src={logoFull} alt="Bambino Logo" width="200" height="41" />
        </ClickableNavbarBrand>
      </Navbar>

      <ChildrenContainer>
        {children}
      </ChildrenContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  font-family: Effra-Light;
  height: 80vh;
`;

const ChildrenContainer = styled.div`
  padding: 2rem;
  background-color: ${whiteBackgorund};
  background-size: cover;
  height: 100%;
`;

const BambinoLogo = styled.img`
  width: 200px;
  height: 41px;
`;

const ClickableNavbarBrand = styled(NavbarBrand)`
  &:hover {
    cursor: pointer;
  }
`;

export default MainLayout;
