/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'reactstrap';

import { AvatarImage } from '../../../components/styles/Images';
import { BaseButton } from '../../../components/styles/Buttons';
import { CheckBoxList } from '../../../components/styles/Fields';
import { ConsentContainer, InternalScrollableContainer } from '../../../components/styles/Containers';
import { ConsentSitter } from '../../../types/SitterTypes';
import { DeclineLink } from '../../../components/styles/Texts';
import { Parent } from '../../../types/ParentTypes';
import { StepTitle, SubTitle } from '../../../components/styles/Titles';

export interface Props {
  parent: Parent | null;
  parentalConfirm: (parent: Parent) => void;
  parentalDecline: () => void;
  setParent: React.Dispatch<React.SetStateAction<Parent | null>>;
  sitter: ConsentSitter | null;
}

const CompleteInformationStep: React.FC<Props> = ({
  parent,
  setParent,
  parentalConfirm,
  parentalDecline,
  sitter,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [firstName, setFirstName] = useState(parent?.first_name || '');
  const [lastName, setLastName] = useState(parent?.last_name || '');
  const [isGuardian, setIsGuardian] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const termsOfUseLink = process.env.REACT_APP_BAMBINO_TERMS_OF_USE || '';

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setParent((prevParent) => {
      if (prevParent) {
        const newParent: Parent = {
          ...prevParent,
          first_name: firstName,
          last_name: lastName,
          name: `${firstName} ${lastName}`,
        };

        parentalConfirm(newParent);

        return newParent;
      }

      return null;
    });
  };

  useEffect(() => {
    if (firstName && lastName && isGuardian && isAuthorized && isAgreed) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [firstName, lastName, isGuardian, isAuthorized, isAgreed]);

  return (
    <ConsentContainer>
      {sitter?.photo_url && (
        <>
          <AvatarImage>
            <img src={sitter.photo_url} width="100" height="100" />
          </AvatarImage>
          <br />
        </>
      )}

      <StepTitle>
        {sitter?.name || 'This person'} needs your parental consent
      </StepTitle>

      <p>
        {sitter?.name || 'This sitter'} has signed up to be a Bambino Sitter. As their parent
        or legal guardian, we need your consent to proceed.
        <br />
        Please read the Terms and Conditions below.
      </p>

      <SubTitle>
        Your Full Name
      </SubTitle>

      <Form
        data-testid="consent-form"
        onSubmit={handleSubmit}
      >
        <Row>
          <Col xs={{ size: 3, offset: 3 }}>
            <Input
              aria-label="firstName"
              name="firstName"
              placeholder="First Name"
              value={firstName}
              onChange={event => setFirstName(event.currentTarget.value)}
            />
          </Col>
          <Col xs={{ size: 3 }}>
            <Input
              aria-label="lastName"
              name="lastName"
              placeholder="Last Name"
              value={lastName}
              onChange={event => setLastName(event.currentTarget.value)}
            />
          </Col>
        </Row>

        <SubTitle>Terms & Conditions</SubTitle>

        <InternalScrollableContainer>
          <p>
            By clicking the “I give consent” button at the bottom of the page, you are representing and warranting that:
          </p>

          <ul>
            <li>
              You are the parent or legal guardian of the subject user;
            </li>
            <li>
              You have reviewed and agree to the Terms of Use of the Bambino Service, accessible via &nbsp;
              <a href={termsOfUseLink} target="_blank" rel="noreferrer">this link</a>&nbsp;
              on behalf of the subject user;
            </li>
            <li>
              You agree to have the ability to monitor the account of the subject user and subject
              user’s activities relative to the Bambino Service (including any communications sent to
              and from the subject user’s account); and
            </li>
            <li>
              You, on behalf of the subject user, authorize the creation of a Braintree Account for
              the subject user through the Bambino Service, including any transmission of funds to
              the subject user in relation thereto.
            </li>
            <li>
              As parent or legal guardian, you authorize the subject user to become a registered user
              of the Bambino Service and grant permission for the display of any personal information
              or images that the subject user posted via his or her public profile on the Bambino
              Service.
            </li>
          </ul>

          <p>
            Please be advised: The Bambino Service is limited to providing a venue and related tools
            for babysitters and those seeking babysitting services to connect, learn about one
            another and, if they elect to do so, arrange babysitting services. Bambino has no control
            over, or responsibility for, the contact between users, the conduct of our users, or the
            truth or accuracy of the information that users post on the Bambino Service and makes no
            guarantees as to its correctness, completeness, or accuracy of such user-submitted
            information and cannot verify or otherwise ascertain the accuracy of such user-submitted
            information.  Bambino is not an employer, agent or vendor of any users and all users are
            solely responsible for complying with all applicable laws in connection with any
            relationship they establish.
          </p>
        </InternalScrollableContainer>

        <div>
          <CheckBoxList>
            <li>
              <label className="check">
                <input
                  type="checkbox"
                  checked={isGuardian}
                  onChange={() => setIsGuardian(prevValue => !prevValue)}
                />
                <i>&#10003;</i>
                &nbsp; I am the guardian of {sitter?.name || 'this person'}
              </label>
            </li>
            <li>
              <label className="check">
                <input
                  type="checkbox"
                  checked={isAuthorized}
                  onChange={() => setIsAuthorized(prevValue => !prevValue)}
                />
                <i>&#10003;</i>
                &nbsp; I authorize {sitter?.name || 'this person'} to be a sitter in the Bambino Network
              </label>
            </li>
            <li>
              <label className="check">
                <input
                  type="checkbox"
                  checked={isAgreed}
                  onChange={() => setIsAgreed(prevValue => !prevValue)}
                />
                <i>&#10003;</i>
                &nbsp; I have read and agree to the Terms and Conditions
              </label>
            </li>
          </CheckBoxList>
        </div>

        <div className="d-flex justify-content-left align-items-center">
          <DeclineLink onClick={parentalDecline}>
            I decline consent
          </DeclineLink>
          <BaseButton
            color="info"
            type="submit"
            disabled={disabled}
          >
            I give consent
          </BaseButton>
        </div>
      </Form>
    </ConsentContainer>
  );
};

export default CompleteInformationStep;
