import React from 'react';

import CompleteInformationStep from './steps/CompleteInformationStep';
import CompleteRecommendationStep from './steps/CompleteRecommendationStep';
import ConsentDeniedStep from './steps/ConsentDeniedStep';
import ConsentGivenRecommendedStep from './steps/ConsentGivenRecommendedStep';
import ConsentGivenStep from './steps/ConsentGivenStep';
import ConsentMessage from './steps/ConsentMessage';
import Login from '../login/Login';
import MainLayout from '../../components/layout/MainLayout';
import Spinner from '../../components/common/Spinner';
import useConsent from './useConsent';
import { ScrollableContainer } from '../../components/styles/Containers';
import { Steps } from '../../types/ConsentTypes';

const Consent = () => {
  const {
    isDataLoaded,
    mailBody,
    mailSubject,
    nextStep,
    parent,
    parentalConfirm,
    parentalDecline,
    parentalDeclineRetracted,
    recommendationConfirm,
    setParent,
    setSitter,
    sitter,
    step,
  } = useConsent();

  const displayCurrentStep = () => {
    switch (step) {
      case Steps.LOGIN:
        return (
          <Login
            actionAfterVerify={() => nextStep(Steps.COMPLETE_INFORMATION)}
            actionAfterError={() => null}
          />
        );

      case Steps.COMPLETE_INFORMATION:
        return isDataLoaded ? (
          <CompleteInformationStep
            parent={parent}
            parentalConfirm={parentalConfirm}
            parentalDecline={parentalDecline}
            setParent={setParent}
            sitter={sitter}
          />
        ) : <Spinner />;

      case Steps.CONSENT_ALREADY_GIVEN:
        return <ConsentGivenStep />;

      case Steps.CONSENT_GIVEN_COMPLETE_RECOMMENDATION:
        return (
          <CompleteRecommendationStep
            setSitter={setSitter}
            sitter={sitter}
            recommendationConfirm={recommendationConfirm}
          />
        );

      case Steps.CONSENT_GIVEN_NOT_RECOMMENDED:
        return <ConsentMessage message="Looks like an error occurred" />;

      case Steps.CONSENT_GIVEN_RECOMMENDED:
        return (
          <ConsentGivenRecommendedStep
            mailBody={mailBody}
            mailSubject={mailSubject}
            sitter={sitter}
          />
        );

      case Steps.CONSENT_DENIED:
        return (
          <ConsentDeniedStep
            sitter={sitter}
            parentalDeclineRetracted={parentalDeclineRetracted}
          />
        );

      default:
        return <ConsentMessage message="Looks like the consent code is invalid" />;
    }
  };

  return (
    <MainLayout>
      <ScrollableContainer>
        {displayCurrentStep()}
      </ScrollableContainer>
    </MainLayout>
  );
};

export default Consent;
