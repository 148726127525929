/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Container, Row } from 'reactstrap';

import useLogout from '../../login/useLogout';
import { BaseButton } from '../../../components/styles/Buttons';
import { ReducedSitter } from '../../../types/SitterTypes';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  isDataLoaded: boolean;
  parent_recommended: boolean;
  recommendation_submitted: boolean;
  sitter: ReducedSitter | null;
}

const RecommendationMessageStep: React.FC<Props> = ({
  isDataLoaded,
  recommendation_submitted,
  parent_recommended,
  sitter,
}) => {
  const logout = useLogout();

  const alreadyRecommended = !recommendation_submitted && parent_recommended;
  const recommendationGiven = recommendation_submitted && parent_recommended;
  const recommendationFailed = (
    (recommendation_submitted && !parent_recommended) ||
    (!recommendation_submitted && !parent_recommended)
  );

  const goToBambino: React.MouseEventHandler<Element> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.replace(bambinoLink);
  };

  return (
    <Container>
      <Row>
        {!isDataLoaded && <p>An error occurred</p>}
        {isDataLoaded && (
          <>
            {alreadyRecommended && (
              <>
                <StepTitle>
                  Recommendation already submitted
                </StepTitle>
                <p>
                  It looks like you already gave a recommendation to this user.
                </p>
              </>
            )}
            {recommendationGiven && (
              <>
                <StepTitle>
                  Recommendation given
                </StepTitle>
                <p>
                  Thank you for providing your recommendation for {sitter?.name || 'this sitter'}.
                </p>
              </>
            )}
            {recommendationFailed && (
              <>
                <StepTitle>
                  Recommendation failed
                </StepTitle>
                <p>
                  An error occurred
                </p>
              </>
            )}
          </>
        )}
      </Row>

      <br />

      <BaseButton
        color="info"
        onClick={goToBambino}
      >
        Go Back
      </BaseButton>
    </Container>
  );
};

export default RecommendationMessageStep;
