/* eslint-disable max-len */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import email from '../../../assets/img/share-icons/email.png';
import facebook from '../../../assets/img/share-icons/facebook.png';
import message from '../../../assets/img/share-icons/message.png';
import useLogout from '../../login/useLogout';
import { BaseButton } from '../../../components/styles/Buttons';
import { ConsentSitter } from '../../../types/SitterTypes';
import { isIPhone, isMobile } from '../../../helpers/DeviceHelper';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  mailBody: string;
  mailSubject: string;
  sitter: ConsentSitter | null;
}

const ConsentGivenRecommendedStep: React.FC<Props> = ({
  mailBody,
  mailSubject,
  sitter,
}) => {
  const logout = useLogout();
  const sitterName = sitter?.name || 'this sitter';
  const mobile = isMobile();
  const iPhone = isIPhone();

  const facebookShareLink = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2Fbambinositters&amp;src=sdkpreparse';
  const notIPhoneMessage = 'sms:?body=You%20should%20check%20out%20Bambino!%20It%27s%20a%20private%20community%20where%20friends%20and%20neighbors%20find%20trusted%2C%20parent-recommended%20Sitters.%20It%20seems%20like%20it%20really%20is%20babysitting%20made%20a%20whole%20lot%20easier%20and%20I%20think%20you%27ll%20love%20it!';
  const iPhoneMessage = 'sms:&body=You should check out Bambino! It is a private community where friends and neighbors find trusted, parent-recommended Sitters. It seems like it really is babysitting made a whole lot easier and I think you will love it!';
  const emailLink = `mailto:?subject=${mailSubject}&body=${mailBody}`;

  const handleGoBackClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    logout();

    const bambinoLink = process.env.REACT_APP_BAMBINO_LANDING_URL || '';
    window.location.href = bambinoLink;
  };

  return (
    <Container>
      <StepTitle>
        Help {sitterName} get their first sit!
      </StepTitle>

      <p>
        Share with your friends! The more parents and sitters {sitterName} is
        connected with, the more jobs {sitterName} will get!
      </p>

      <Row>
        <Col xs={{ size: 2, offset: mobile ? 3 : 4 }}>
          <a href={facebookShareLink} target="_blank" rel="noreferrer">
            <img src={facebook} alt="Share in Facebook" title="Share in Facebook" />
          </a>
        </Col>

        {mobile && (
          <Col xs={2}>
            <a href={iPhone ? iPhoneMessage : notIPhoneMessage}>
              <img src={message} alt="Send a message" title="Send a message" />
            </a>
          </Col>
        )}

        <Col xs={2}>
          <a href={emailLink} target="_blank" rel="noreferrer">
            <img src={email} alt="Send an email" title="Send an email" />
          </a>
        </Col>
      </Row>

      <br />
      <br />

      <BaseButton color="info" type="button" onClick={handleGoBackClick}>
        Go Back
      </BaseButton>
    </Container>
  );
};

export default ConsentGivenRecommendedStep;
