import styled from 'styled-components';

import { bambinoLightGrey, white } from '../../assets/colors';

export const AvatarImage = styled.div`
  display: flex;
  justify-content: center;

  img {
    height: 100px;
    width: 100px;
    border: 4px solid ${white};
    border-radius: 50%;
    box-shadow: 0 0 2px ${bambinoLightGrey};
    margin: 0 auto;
    overflow: hidden;
  }
`;
