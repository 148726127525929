import React, { useEffect, useState } from 'react';
import ReactStars from 'react-stars';
import { Col, Container, Form, Row } from 'reactstrap';

import { bambinoLightOrange, bambinoOrange } from '../../../assets/colors';
import { BaseButton } from '../../../components/styles/Buttons';
import { RecommendationPayload } from '../useRecommendation';
import { ReducedSitter } from '../../../types/SitterTypes';
import { StepTitle } from '../../../components/styles/Titles';

export interface Props {
  isLoading: boolean;
  recommendationConfirm: (loginToken: string) => void;
  sitter: ReducedSitter | null;
  setPayload: React.Dispatch<React.SetStateAction<RecommendationPayload>>;
}

const RatingRecommendationStep: React.FC<Props> = ({
  isLoading,
  recommendationConfirm,
  sitter,
  setPayload,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [payloadUpdated, setPayloadUpdated] = useState(false);
  const [rating, setRating] = useState(0);
  const [about, setAbout] = useState('');

  useEffect(() => {
    if (rating > 0 && about && about.length >= 25) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [rating, about]);

  useEffect(() => {
    if (payloadUpdated) {
      const loginToken = localStorage.getItem('bambino-sitters-token') || '';
      recommendationConfirm(loginToken);
    }
  }, [payloadUpdated]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setPayload((prevPayload) => {
      setPayloadUpdated(true);

      return ({
        ...prevPayload,
        rating,
        about_sitter: about,
      });
    });
  };

  return (
    <Container>
      <StepTitle>
        How would you rate {sitter?.name || 'this person'} as a sitter?
      </StepTitle>

      <Form
        data-testid="rating-recommendation-form"
        onSubmit={handleSubmit}
      >
        <Row>
          <p>
            How would you rate your overall experience with {sitter?.name || 'this sitter'}?
          </p>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 3 }} className="d-flex justify-content-center">
            <ReactStars
              edit
              count={5}
              value={rating}
              size={40}
              color1={bambinoLightOrange}
              color2={bambinoOrange}
              onChange={newRating => setRating(newRating)}
              half={false}
            />
          </Col>
        </Row>

        <Row>
          <p>
            Please provide a recommendation for {sitter?.name || 'this person'} as a babysitter:
          </p>
        </Row>

        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <textarea
              className="form-control"
              rows={5}
              value={about}
              onChange={event => setAbout(event.currentTarget.value)}
              placeholder="Please provide at least 25 characters"
            />
          </Col>
        </Row>

        <br />

        <BaseButton
          color="info"
          type="submit"
          disabled={disabled || isLoading}
        >
          {isLoading ? (
            <div>
              <div className="spinner-border spinner-border-sm ml-1" role="status" />
            </div>
          ) : 'Submit'}
        </BaseButton>
      </Form>
    </Container>
  );
};

export default RatingRecommendationStep;
