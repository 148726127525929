import React from 'react';
import { Mail, Phone } from 'react-feather';

import FacebookCircle from '../../assets/img/social-icons/facebook-circle.png';
import InstagramCircle from '../../assets/img/social-icons/instagram-circle.png';
import TwitterCircle from '../../assets/img/social-icons/twitter-circle.png';
import { white } from '../../assets/colors';
import {
  StyledCopyrightLabel,
  StyledFooter,
  StyledFooterBottom,
  StyledFooterIcon,
  StyledFooterInfoSection,
  StyledFooterLink,
  StyledFooterSocialIconsContainer,
  StyledFooterTop,
} from '../styles/FooterStyles';

const Footer: React.FC = () => {
  const privacyPolicyLink = process.env.REACT_APP_BAMBINO_PRIVACY_POLICY || '';
  const termsOfUseLink = process.env.REACT_APP_BAMBINO_TERMS_OF_USE || '';

  return (
    <StyledFooter>
      <StyledFooterTop>
        <div>
          <StyledFooterLink
            href={privacyPolicyLink}
            target="_blank"
            hasBorderRight
          >
            Privacy Policy
          </StyledFooterLink>
          <StyledFooterLink
            href={termsOfUseLink}
            target="_blank"
            addPaddingLeft
          >
            Terms of use
          </StyledFooterLink>
        </div>
        <StyledFooterInfoSection>
          <StyledFooterLink href="mailto:support@bambinositters.com">
            <i className="mr-1">
              <Mail size={18} color={white} />
            </i>
            support@bambinositters.com
          </StyledFooterLink>
          <StyledFooterLink href="tel:844-622-6246">
            <i className="mr-1">
              <Phone size={18} color={white} />
            </i>
            (844) 622-6246
          </StyledFooterLink>
        </StyledFooterInfoSection>
      </StyledFooterTop>

      <StyledFooterBottom>
        <div className="d-flex align-items-center">
          <StyledCopyrightLabel>
            © {new Date().getFullYear()}
            {' '}<StyledFooterLink href="https://www.bambinositters.com" target="_blank">
              Bambino
            </StyledFooterLink>
          </StyledCopyrightLabel>
        </div>
        <StyledFooterSocialIconsContainer>
          <StyledFooterLink href="https://www.facebook.com/bambinositters" target="_blank">
            <StyledFooterIcon src={FacebookCircle} />
          </StyledFooterLink>
          <StyledFooterLink href="https://twitter.com/bambinositters" target="_blank">
            <StyledFooterIcon src={TwitterCircle} />
          </StyledFooterLink>
          <StyledFooterLink href="https://instagram.com/bambinositters" target="_blank">
            <StyledFooterIcon src={InstagramCircle} />
          </StyledFooterLink>
        </StyledFooterSocialIconsContainer>
      </StyledFooterBottom>
    </StyledFooter>
  );
};

export default Footer;
