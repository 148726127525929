import styled from 'styled-components';
import { FormGroup } from 'reactstrap';
import { IMaskInput } from 'react-imask';

export const CenteredInput = styled(IMaskInput)`
  text-align: center;
`;

export const RadioOptionsGroup = styled(FormGroup)`
  text-align: left;
`;

export const CheckBoxList = styled.ul`
  padding-left: 0;
  text-align: left;
  list-style: none;

  li {
    margin-top: 0.5em;
  }

  label {
    padding-left: 0;
    padding-top: 0;
    vertical-align: middle;
    font-family: Effra-Light;
    font-size: 15px;
  }

  .check {
    margin-top: 0;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }

  /* HIDE CHECKBOX */
  .check > input {
    visibility: hidden;
    position: absolute;
  }

  /* CHECKBOX STYLE */
  .check > i {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 0;
    transition: 0.2s;
    box-shadow: inset 0 0 0 0;
    border: 1px solid #0dc5d7;
    background: #f7f7f7;
    color: #f7f7f7;
    font-size: 10px;
    padding-left: 4px;
    padding-top: 2px;
  }

  div .check > i {
    padding-top: 1px;
  }

  ul .check > i {
    padding-top: 2px;
  }

  .check:hover > i {
    box-shadow: inset 0 0 0 0;
    background: #f7f7f7;
    color: #f7f7f7;
  }

  .check > input:checked + i {
    box-shadow: inset 0 0 0 0;
    background: #0dc5d7;
    color: #ffffff;
  }

  .check > input + i + span {
    color: #333333;
    font-weight: lighter;
  }
`;

export const ThumbsRadio = styled.div`
  display: flex;

  .form-check {
    padding-left: 0;
    padding: 0 0.5em;
  }

  /* HIDE RADIO */
  label > input {
    visibility: hidden;
    position: absolute;
  }

  img {
    cursor: pointer;
  }
`;
