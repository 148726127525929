export interface ConsentStatus {
  parent_consented: boolean;
  consent_submitted: boolean;
  consent_request_response: string;
}

export interface RecommendationStatus {
  parent_recommended: boolean;
  recommendation_submitted: boolean;
  recommendation_request_response: string;
}

export enum Steps {
  LOGIN = 'LOGIN',
  COMPLETE_INFORMATION = 'COMPLETE_INFORMATION',
  CONSENT_ALREADY_GIVEN = 'CONSENT_ALREADY_GIVEN',
  CONSENT_GIVEN_COMPLETE_RECOMMENDATION = 'CONSENT_GIVEN_COMPLETE_RECOMMENDATION',
  CONSENT_GIVEN_NOT_RECOMMENDED = 'CONSENT_GIVEN_NOT_RECOMMENDED',
  CONSENT_GIVEN_RECOMMENDED = 'CONSENT_GIVEN_RECOMMENDED',
  CONSENT_DENIED = 'CONSENT_DENIED',
}
