import styled from 'styled-components';
import { Button } from 'reactstrap';

import { bambinoLightTurqoise, bambinoTurqoise, white } from '../../assets/colors';

export const BaseButton = styled(Button)`
  &.btn {
    border: none;
    border-radius: 3px;
    font-weight: 500;
    font-family: Effra;
    text-transform: uppercase;
  }

  &.btn-info {
    background-color: ${bambinoTurqoise};
    color: ${white};
    width: 180px;
  }

  &.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:visited, .btn-info:link {
    background-color: ${bambinoLightTurqoise};
  }
`;
