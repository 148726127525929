export const isMobile = () => {
  if (navigator.userAgent.match(/Android/i)) {
    return true;
  }

  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return true;
  }

  if (navigator.userAgent.match(/BlackBerry/i)) {
    return true;
  }

  if (navigator.userAgent.match(/Opera Mini/i)) {
    return true;
  }

  if (navigator.userAgent.match(/IEMobile/i)) {
    return true;
  }

  return false;
};

export const isIPhone = () => navigator.userAgent.match(/iPhone/i);
