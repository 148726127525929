import { ToastOptions } from 'react-toastify';

export const errorToastOptions: ToastOptions = {
  type: 'error',
  theme: 'colored',
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};
